import React, { Component } from "react";
import { Link } from "react-router-dom";
import BackButton from "./BackButton";

const cardData = [
    { image: 'https://celuloidemovie.com:8443/images/DominicanRepublic.PNG', title: 'Dominican Republic' , link:"/search/dominicanrepublic"},
    { image: 'https://celuloidemovie.com:8443/images/Honduras.PNG', title: 'Honduras' , link:"/search/honduras"},
    { image: 'https://celuloidemovie.com:8443/images/Argentina.PNG', title: 'Argentina' , link:"/search/argentina"},
    { image: 'https://celuloidemovie.com:8443/images/Chile.PNG', title: 'Chile' , link:"/search/chile"},
    { image: 'https://celuloidemovie.com:8443/images/Colombia.PNG', title: 'Colombia' , link:"/search/colombia"},
    { image: 'https://celuloidemovie.com:8443/images/Mexico.PNG', title: 'Mexico', link:"/search/mexico" },
    { image: 'https://celuloidemovie.com:8443/images/spain.PNG', title: 'España', link:"/search/spain" },
    { image: 'https://celuloidemovie.com:8443/images/Brazil.PNG', title: 'Brazil', link:"/search/brazil" },
    { image: 'https://celuloidemovie.com:8443/images/Panama.PNG', title: 'Panama', link:"/search/panama" },
    { image: 'https://celuloidemovie.com:8443/images/Peru.PNG', title: 'Peru', link:"/search/peru" },
    { image: 'https://celuloidemovie.com:8443/images/UnitedStates.PNG', title: 'United States', link:"/search/unitedstates" },
    { image: 'https://celuloidemovie.com:8443/images/SourthKorea.PNG', title: 'Sourth Korea', link:"/search/sourthkorea" },
    { image: 'https://celuloidemovie.com:8443/images/LATAM.PNG', title: 'Latam', link:"/search/latam" },
    { image: 'https://celuloidemovie.com:8443/images/Sports.PNG', title: 'Sports', link:"/search/sports" },
  ];

const Card = ({ image, title, link }) => {
  return (
    <Link to={link} className="bg-white shadow-lg rounded-lg overflow-hidden w-60 h-50">
      <img src={image} alt={title} className="w-full h-40 object-cover" />
      <div className="p-4">
        <h2 className="text-lg font-semibold text-black">{title}</h2>
      </div>
    </Link>
  );
};

const SearchHomeComponent = (props) => {
  return (
    <div className="flex flex-col items-center p-4 bg-secondary-400 hover:bg-secondary-200 text-white font-bold py-1 px-2 rounded">
      {/* Back Button */}
      <div className="movie-main-container-topleft-backbutton">
        <BackButton />
      </div>

      {/* Cards Container */}
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3 lg:gap-6">
        {cardData.map((card, index) => (
          <Card key={index} image={card.image} title={card.title} link={card.link} />
        ))}
      </div>
    </div>
  );
};

export default SearchHomeComponent;
